import React, { useState } from 'react';
import { Box, TextField, Button, Paper, Typography } from '@mui/material';

type ChatMessage = {
  from: string;
  text: string;
};

const VestAIchat = () => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [input, setInput] = useState('');

  const handleSend = () => {
    if (input.trim() === '') return;

    const newMessage: ChatMessage = { from: 'user', text: input };
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    const dummyReply: ChatMessage = { from: 'system', text: 'This is a dummy reply.' };
    setMessages((prevMessages) => [...prevMessages, dummyReply]);

    setInput('');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Paper sx={{ flex: 1, overflow: 'auto', p: 2 }}>
        {messages.map((message, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: message.from === 'user' ? 'flex-end' : 'flex-start',
              mb: 1,
            }}
          >
            <Typography
              sx={{
                p: 1,
                borderRadius: 1,
                bgcolor: message.from === 'user' ? 'primary.main' : 'grey.300',
                color: message.from === 'user' ? 'white' : 'black',
              }}
            >
              {message.text}
            </Typography>
          </Box>
        ))}
      </Paper>
      <Box sx={{ display: 'flex', p: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message..."
        />
        <Button variant="contained" color="primary" onClick={handleSend}>
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default VestAIchat;
