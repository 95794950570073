// routes
import {
  PATH_APPS,
  PATH_CHECKLISTS,
  PATH_DASHBOARD,
  PATH_FBS, PATH_REPORTS,
  PATH_ROLES,
  PATH_SANDBOX, PATH_SHIFTS,
  PATH_ADMIN, PATH_USERS,
  PATH_REQ_MGMT, PATH_NOTIF_CENTER,
  PATH_STAFF_DEPLOYMENT,
} from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from "../../../components/Iconify";
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
const getIconIconify = (name: string, size: number=32) => (
  <Iconify icon={name||'bi:file-check'} width={size} height={size} />
);

const icoIsExternal = (<Iconify icon="heroicons-solid:external-link" />);
const isVestaOwnApp = (window.location.origin === 'https://app.teamvesta.com');
const isVestaWebApp = (window.location.origin === 'https://webapp.teamvesta.com');

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  /* Custom UXWing/Noun Project Icons */
  feedback: getIcon('uxwing_topic_discussion'),
  checklist: getIcon('noun_checklist'),
  shift: getIcon('noun_shift_1person_clock'),
  reports: getIconIconify('carbon:report'), // hexagon:icon-park-outline:sales-report
  roles: getIconIconify('fa6-solid:user-lock'),
  qr: getIcon('qr_code_svgrepo'),
  alerts: getIconIconify('icon-park-outline:report'),
  /* Iconify */
  checklistIfy: getIconIconify('fa6-solid:list-check'),
  superAdmin: getIconIconify('cib:f-secure'), //dashicons:superhero
};

// roles: ['user', 'vesta-super-admin', 'dev-thinkdj-superadmin', 'admin', 'manager','vendor-supervisor']

const navConfigTmp = [

  /* * /
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Dashboard', path: PATH_CHECKLISTS.dashboards.summary, icon: ICONS.dashboard },
      //{ title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      //{ title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      //{ title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      //{ title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },
  /* */

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'admin',
    items: [

      // VESTA / REQUESTS MANAGEMENT
      {
        title: 'Task Management',
        roles: ['vesta-super-admin'],
        path: PATH_REQ_MGMT.root,
        icon: ICONS.menuItem,
        children: [
          { title: 'Dashboard',soon:true, path: PATH_REQ_MGMT.requestsDash, roles:['vesta-super-admin'] },
          { title: 'Task/Snag',soon:true, path: PATH_REQ_MGMT.list, roles:['vesta-super-admin'] },
          // { title: 'Create', path: PATH_REQ_MGMT.create, roles:['vesta-super-admin'] },
          { title: 'Analytics',soon:true, path: PATH_REQ_MGMT.analytics, roles:['vesta-super-admin'] },
          // { title: 'Reports', path: PATH_REQ_MGMT.reports, roles:['vesta-super-admin'] },
          // { title: 'Configure Escalations',soon:true, path: PATH_REQ_MGMT.escalations, roles:['vesta-super-admin'] },
          // { title: 'Manage Users',soon:true, path: PATH_ADMIN.dataManager, roles:['vesta-super-admin'] },
          { title: 'Department Groups',soon:true, path: PATH_REQ_MGMT.depGroups, roles:['vesta-super-admin'] },
          // { title: 'Area/Locations',soon:true, path: PATH_REQ_MGMT.areaLocations, roles:['vesta-super-admin'] },
          // { title: 'Department Configuration',soon:true, path: PATH_REQ_MGMT.departmentConfig, roles:['vesta-super-admin'] },
          { title: 'Configuration',soon:true, path: PATH_REQ_MGMT.configuration, roles:['vesta-super-admin'] },
        ],
      },

       // Coming Soon REQUESTS MANAGEMENT
       {
        title: 'Task Management',
        roles: ['user', 'dev-thinkdj-superadmin', 'admin', 'manager','vendor-supervisor'],
        path: "soon",
        icon: ICONS.menuItem,
        children: [
          { title: 'Task/Snag', path:"", roles:['user', 'dev-thinkdj-superadmin', 'admin', 'manager','vendor-supervisor'] },
          { title: 'Analytics', path:"", roles:['user', 'dev-thinkdj-superadmin', 'admin', 'manager','vendor-supervisor'] },
          { title: 'Configure Escalations', path:"", roles:['user', 'dev-thinkdj-superadmin', 'admin', 'manager','vendor-supervisor'] },
          { title: 'Manage Users', path:"", roles:['user', 'dev-thinkdj-superadmin', 'admin', 'manager','vendor-supervisor'] },
          { title: 'Department Groups', path:"", roles:['user', 'dev-thinkdj-superadmin', 'admin', 'manager','vendor-supervisor'] },
          { title: 'Area/Locations', path:"", roles:['user', 'dev-thinkdj-superadmin', 'admin', 'manager','vendor-supervisor'] },
        ],
      },

      // FBS: Feedback System
      {
        title: 'Feedback',
        roles: ['user'],
        path: PATH_FBS.dashboards.summary,
        icon: ICONS.feedback,
        children: [
          { title: 'Dashboard', path: isVestaOwnApp ? 'https://webapp.teamvesta.com/feedback/dashboard' : PATH_FBS.dashboards.summary, roles:['user'], children_disabled: [
                { title: 'Summary', path: isVestaOwnApp ? 'https://webapp.teamvesta.com/feedback/dashboard' : PATH_FBS.dashboards.summary },
                { title: 'Analytics', path: isVestaOwnApp ? 'https://webapp.teamvesta.com/feedback/analytics' : PATH_FBS.dashboards.analytics  },
          ] },
          { title: 'Analytics', path: isVestaOwnApp ? 'https://webapp.teamvesta.com/feedback/analytics' : PATH_FBS.dashboards.analytics, roles:['manager'] },
          { title: 'Feedback', path: isVestaOwnApp ? 'https://webapp.teamvesta.com/feedback/list' : PATH_FBS.manage.feedback, roles:['user'] },
          { title: 'Locations', path: isVestaOwnApp ? 'https://webapp.teamvesta.com/feedback/locations' : PATH_FBS.manage.locations, roles:['manager'] },
          { title: 'Location <=> Staff Map', path: isVestaOwnApp ? 'https://webapp.teamvesta.com/feedback/location-to-staff-on-duty-mapping' : PATH_FBS.manage.locToStaffOnDuty, roles:['manager'] },
          { title: 'Templates', path: isVestaOwnApp ? 'https://webapp.teamvesta.com/feedback/templates' : PATH_FBS.manage.templates, roles:['admin'] },
        ],
      },

      // Deployments
      {
        title: 'Staff Deployment',
        roles: ['admin'],
        path: PATH_STAFF_DEPLOYMENT.root,
        icon: ICONS.roles,
        children: [
          { title: 'Deployments', path: PATH_STAFF_DEPLOYMENT.sheets, roles:['admin']},
          { title: 'History', path: PATH_STAFF_DEPLOYMENT.history, roles:['admin'] },
          { title: 'Vendors', path: PATH_STAFF_DEPLOYMENT.vendors, roles:['admin'] },
          { title: 'Templates', path: PATH_STAFF_DEPLOYMENT.templates, roles:['admin'] },
        ],
      },
      // Deployments for Vendor Supervisor
       {
        title: 'Staff Deployment',
        roles: ['vendor-supervisor'],
        path: PATH_STAFF_DEPLOYMENT.root,
        icon: ICONS.roles,
        children: [
          { title: 'Deployments', path: PATH_STAFF_DEPLOYMENT.sheets, roles:['vendor-supervisor'] },
          { title: 'History', path: PATH_STAFF_DEPLOYMENT.history, roles:['vendor-supervisor'] },
          // { title: 'Vendors', path: PATH_STAFF_DEPLOYMENT.vendors, roles:['vendor-supervisor'] },
          // { title: 'Templates', path: PATH_STAFF_DEPLOYMENT.templates, roles:['vendor-supervisor'] },
        ],
      },

      // CHECKLISTS
      {
        title: 'Checklist',
        roles: ['user'],
        path: PATH_CHECKLISTS.root,
        icon: ICONS.checklistIfy,
        children: [
          { title: 'Dashboard', path: isVestaWebApp ? 'https://app.teamvesta.com/checklist/dashboard' : PATH_CHECKLISTS.dashboards.summary, icon: ICONS.dashboard, roles:['user'] },
          { title: 'Checklists', path: isVestaWebApp ? 'https://app.teamvesta.com/checklist/instances' : PATH_CHECKLISTS.manage.instances,  roles:['user'] }, // Instances
          { title: 'Templates', path: isVestaWebApp ? 'https://app.teamvesta.com/checklist/blueprints' : PATH_CHECKLISTS.manage.blueprints, roles:['admin'] }, // Templates
          //{ title: 'Checklists', path: PATH_CHECKLISTS.manage.checklists },
          //{ title: 'Templates', path: PATH_CHECKLISTS.manage.templates },
          { title: 'Schedules', path: isVestaWebApp ? 'https://app.teamvesta.com/checklist/schedules' : PATH_CHECKLISTS.manage.schedules,roles:['admin'] },
          { title: 'Locations', path: isVestaWebApp ? 'https://app.teamvesta.com/checklist/cl-locations' : PATH_CHECKLISTS.manage.locations, roles:['manager'] },
          { title: 'Checklist Items', path: isVestaWebApp ? 'https://app.teamvesta.com/checklist/items' : PATH_CHECKLISTS.manage.items, roles:['admin'] },
          { title: 'Checklist Comments', path: isVestaWebApp ? 'https://app.teamvesta.com/checklist/comments' : PATH_CHECKLISTS.manage.comments, roles:['admin'] },
        ],
      },

      // REPORTING
      {
        title: 'Reports',
        roles: ['manager'],
        path: PATH_REPORTS.root,
        icon: ICONS.reports,
        children: [
          { title: 'Download', path: PATH_REPORTS.download, roles:['manager'] },
        ],
      },


      // SHIFTS
      {
        title: 'Shifts',
        roles: ['manager'],
        path: PATH_SHIFTS.root,
        icon: ICONS.shift,
        children: [
          { title: 'Manage', path: PATH_SHIFTS.manage.shifts, roles:['manager'] },
        ],
      },

      // Notifications Center
      {
        title: 'Notifications Center',
        roles: ['manager'],
        caption: '',
        path: "notifications-center",
        icon: ICONS.chat,
        children: [
          { title: 'Notification Lists', path: PATH_NOTIF_CENTER.notifLists, roles:['manager'] },
          { title: 'Dept-wise Emails', path: PATH_NOTIF_CENTER.deptWiseEmail, roles:['manager'] },
        ],
      },

      // QR CODE GENERATOR
      {
        title: 'QR Code Generator',
        roles: ['manager'],
        path: PATH_APPS.qrCodeGen,
        icon: ICONS.qr,
      },

      // SCHEDULER: SCHEDULED TASKS
      {
        title: 'Scheduled Tasks',
        roles: ['vesta-super-admin','dev-thinkdj-superadmin'],
        path: PATH_APPS.scheduledTasks,
        icon: ICONS.calendar,
      },

      // ORG. ADMIN
      {
        title: 'Admin',
        roles: ['user'],
        caption: '',
        path: "admin",
        icon: ICONS.superAdmin,
        //info: (<Label color="warning" startIcon={<Iconify icon="bi:shield-fill" />}></Label>),
        children: [
          { title: 'Manage Org', path: PATH_ADMIN.manageOrgs, roles:['admin'] },
          { title: 'Manage Departments', path: PATH_ADMIN.manageDepts, roles:['admin'] },
          { title: 'Data Manager', path: PATH_ADMIN.dataManager,roles:['admin'] },
          { title: 'Org Config', path: PATH_ADMIN.orgConfig, roles:['vesta-super-admin'] },
          { title: 'Bulk Data Importer', path: PATH_APPS.bulkBatchCsvDataImporter, roles:['vesta-super-admin'] },
          { title: 'Mobile App Version', path: PATH_APPS.updateMobileVersion, roles:['vesta-super-admin'] },
          // ROLES
          {
            title: 'Manage Users',
            roles: ['user'],
            path: PATH_ROLES.root,
            icon: ICONS.roles,
            children: [
              { title: 'Users', path: PATH_USERS.manage, roles:['admin'] },
              { title: 'User - Org. Mapping', path: PATH_ADMIN.manageUsers, roles:['admin'] },
              { title: 'Roles', path: PATH_ROLES.list, roles:['admin'] },
              { title: 'User - Role Mapping', path: PATH_ROLES.manage.users, roles:['admin'] },
              /* OLD UI LINKED HERE (Temporary) */
              { title: 'User - Dept Mapping', path: PATH_ROLES.vesta.dataManager, info: icoIsExternal, roles:['admin'] },
              { title: 'My Settings', path: PATH_ROLES.vesta.mySettings, info: icoIsExternal, roles:['user'] },
            ],
          },
          { title: 'Users Activity', path: PATH_ADMIN.activityLogs,roles:['admin'] },
          // /ROLES
        ],
      },

      // APP SUPER ADMIN [INTERNAL/DEV]
      /* * /
      {
        title: 'Super Admin',
        roles: ['dev-thinkdj-superadmin'],
        caption: 'UNDER DEV./Secured access',
        path: "super-admin",
        icon: ICONS.alerts,
        info: <Label color="warning"  sx={{ m:0, pr:0 }} startIcon={<Iconify icon="bi:shield-fill" />} />,
        children: [
          { title: 'Dev. Sandbox', path: PATH_SANDBOX.apps.devSandBox },
          { title: 'Data Importer', path: PATH_SANDBOX.apps.reactCSVImporter },
          //{ title: 'Onboarding', path: PATH_SUPER_ADMIN.apps.onboarding },
          //{ title: 'Master Scheduler', path: PATH_SUPER_ADMIN.apps.scheduler },
        ],
      }

     /* * /
      // USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'profile', path: PATH_DASHBOARD.user.profile },
          { title: 'cards', path: PATH_DASHBOARD.user.cards },
          { title: 'list', path: PATH_DASHBOARD.user.list },
          { title: 'create', path: PATH_DASHBOARD.user.new },
          { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
          { title: 'account', path: PATH_DASHBOARD.user.account },
        ],
      },

      // E-COMMERCE
      {
        title: 'e-commerce',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.cart,
        children: [
          { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
          { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
          { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
          { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
          { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
          { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
        ],
      },

      // INVOICE
      {
        title: 'invoice',
        path: PATH_DASHBOARD.invoice.root,
        icon: ICONS.invoice,
        children: [
          { title: 'list', path: PATH_DASHBOARD.invoice.list },
          { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
          { title: 'create', path: PATH_DASHBOARD.invoice.new },
          { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
        ],
      },

      // BLOG
      {
        title: 'blog',
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.blog,
        children: [
          { title: 'posts', path: PATH_DASHBOARD.blog.posts },
          { title: 'post', path: PATH_DASHBOARD.blog.demoView },
          { title: 'create', path: PATH_DASHBOARD.blog.new },
        ],
      },

      /* */
    ],
  },

  /* * /
  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      {
        title: 'mail',
        path: PATH_DASHBOARD.mail.root,
        icon: ICONS.mail,
        info: <Label color="error">+32</Label>,
      },
      { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
      { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
    ],
  },
  /* */

];

// SANDBOX
const sandBoxLinks = {
  subheader: 'dev.sandbox',
  items: [
    {
      title: 'Sandbox',
      path: PATH_SANDBOX.root,
      icon: ICONS.mail,
      children: [
        { title: 'Dev. Sandbox', path: PATH_SANDBOX.apps.devSandBox },
        { title: 'Data Importer', path: PATH_SANDBOX.apps.reactCSVImporter },
      ],
    }
  ]
};

// Append Sandbox Links (if not production)
const navConfig = ('production'===process.env.NODE_ENV)? navConfigTmp : [...navConfigTmp,sandBoxLinks];

export default navConfig;
