import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, Typography, Box, Select, MenuItem } from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import DashWelcome from "src/sections/@dashboard/general/app/DashWelcome";

const Configuration = () => {
  const { user } = useAuth();

  const [selectedOption, setSelectedOption] = useState(
    user?.hotels?.[0] || null
  );

  const navigate = useNavigate();
  // Set the default option on component mount or page revisit
  useEffect(() => {
    if (user?.hotels?.[0] && !selectedOption) {
      setSelectedOption(user.hotels[0]);
    }
  }, [user, selectedOption]);

  const handleSelectChange = (event: any) => {
    setSelectedOption(event.target.value);
    // console.log("Selected Filter Option:", event.target.value);
  };

  const handleUserClick = () => {
    navigate("/requests-mgmt/configuration/dataManager");
  };

  const handleDepartmentClick = () => {
    navigate("/requests-mgmt/configuration/manager");
  };

  const handleLocationClick = () => {
    navigate("/requests-mgmt/configuration/area-locations");
  };

  const handleEscalationConfigClick = () => {
    navigate("/requests-mgmt/configuration/esc-config");
  };

  // Styling Constants
  const cardStyle = {
    borderRadius: 2,
    cursor: "pointer",
    padding: 2,
    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    },
  };

  const centerContent = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const iconStyle = {
    fontSize: 78,
    color: "#5a6e8c",
    marginRight: 16,
  };

  return (
    <>
      {/* Cards Section */}
      <Grid container spacing={4} justifyContent="center" sx={{ padding: 4 }}>
        <DashWelcome displayName={user?.fname ?? user?.username ?? 'User'} org={[user?.hotel_id]} />
        <Grid item xs={12} sm={6} md={6}>
          <Card
            sx={cardStyle}
            onClick={handleUserClick}
          >
            <CardContent sx={centerContent}>
              <Icon icon="mdi:account-group" style={iconStyle} />
              <Typography variant="h6" sx={{ color: "#5a6e8c" }}>
                USERS
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Card
            sx={cardStyle}
            onClick={handleDepartmentClick}
          >
            <CardContent sx={centerContent}>
              <Icon icon="mdi:office-building" style={iconStyle} />
              <Typography variant="h6" sx={{ color: "#5a6e8c" }}>
                DEPARTMENTS & TASK LIST
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Card
            sx={cardStyle}
            onClick={handleLocationClick}
          >
            <CardContent sx={centerContent}>
              <Icon icon="mdi:map-marker" style={iconStyle} />
              <Typography variant="h6" sx={{ color: "#5a6e8c" }}>
                LOCATIONS
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Card
            sx={cardStyle}
            onClick={handleEscalationConfigClick}
          >
            <CardContent sx={centerContent}>
              <Icon icon="mdi:account-switch" style={iconStyle} />
              <Typography variant="h6" sx={{ color: "#5a6e8c" }}>
                ESCALATION MATRIX
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );


};

export default Configuration;
